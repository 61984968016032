import React, { useState } from 'react';
import { TextField, Container } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import LoginIcon from '@mui/icons-material/Login';
import { useAuth } from '../../../provider/AuthProvider';
import './loginPage.css';
import logoIngeniosoft from '../../../assets/img/logo_ingeniosoft.png';
import logoGymmar from '../../../assets/img/logo_alcaldia.png';
import fondoForo from '../../../assets/img/fondo.jpg';

export default function LoginPage() {
  const [dataForm, setDataForm] = useState({ email: '', password: '' });
  const { login, loading } = useAuth();

  const handleLogin = (e) => {
    e.preventDefault();
    login(dataForm);
  };
  return (<>
    <div className='loginLogo'>
      <img src={logoIngeniosoft} alt='logo' height={80} />
    </div>
    <div
      className={'loginPage'}
      style={{ backgroundImage: `url(${fondoForo})` }}
    >
      <Container
        maxWidth="xs"
        className={'formContainer'}
      >
        <img src={logoGymmar} alt={"foromundial"} height={100} />
        <form onSubmit={handleLogin}>
          <TextField
            autoComplete='off'
            name='email'
            label="# Documento"
            variant="outlined"
            fullWidth
            margin="normal"
            required
            type='number'
            value={dataForm.email}
            onChange={(event) => {
              if (/^\d*$/.test(event.target.value)) {
                setDataForm((prevDataForm) => ({ ...prevDataForm, email: event.target.value }))
              }
            }}
          />
          <TextField
            name='password'
            label="Contraseña"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            required
            onChange={(event) => setDataForm((prevDataForm) => ({ ...prevDataForm, password: event.target.value }))}
            value={dataForm.password}
          />
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            disabled={Object.values(dataForm).some((value) => String(value).length < 3) || loading}
            loading={loading}
            loadingPosition="end"
            endIcon={<LoginIcon />}
          >
            Iniciar Sesión
          </LoadingButton>
        </form>
      </Container>
    </div>
  </>
  );
};
