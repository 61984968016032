import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Badge,
  Button,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import {
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import { useAuth } from "../../provider/AuthProvider";

export default function CustomerInfo({ info, editConsumer, openAssessment }) {
  const { token, authUser } = useAuth();
  return (
    <div>
      {[1, 2, 3].includes(authUser?.roleId) && (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            Información Personal
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={3} alignItems={"center"}>
              <Grid item xs={12} sm={10}>
                <ListItem>
                  <ListItemAvatar></ListItemAvatar>
                  <ListItemText
                    primary={`${info.fullName}`}
                    // secondary={`${info.billing_plan.plan} | ${info.membership_status.statusName}`}
                  />
                </ListItem>
              </Grid>
              {[1, 2].includes(authUser?.roleId) && (
                <Grid item xs={12} sm={2} sx={{ textAlign: "center" }}>
                  <Button variant="outlined" onClick={() => editConsumer()}>
                    Editar
                  </Button>
                </Grid>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
      )}
    </div>
  );
}
