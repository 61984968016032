import axios from "axios";
export function customerGetCountries(params) {
  return new Promise((resolve, reject) => {
    axios
      .get("https://countriesnow.space/api/v0.1/countries", { params })
      .then((response) => resolve(response.data))
      .catch((err) => reject(err));
  });
}

export function customerGetCities(country) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          "https://countriesnow.space/api/v0.1/countries/cities", 
          { country },
        )
        .then((response) => resolve(response.data))
        .catch((err) => reject(err));
    });
  }