import React, { useState } from "react";
import {
  Avatar,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { consumeRegister, consumerRegisterEdit } from "../../api/customer";
import { HowToReg as HowToRegIcon } from "@mui/icons-material";
import Swal from "sweetalert2";
import { useAuth } from "../../provider/AuthProvider";
import { customerGetCities } from "../../api/countries";

export default function CustomerForm({
  masters,
  close,
  closeEdit,
  info,
  isEdit,
  countries,
}) {
  const auth = useAuth();
  const [selectedCountry, setSelectedCountry] = useState("");
  const [filteredCities, setFilteredCities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [progressBar, setProgressBar] = useState(15);
  const [formDataErr, setFormDataErr] = useState({});
  const [formData, setFormData] = useState(
    isEdit === true
      ? {
          fullName: info?.fullName,
          email: info?.email,
          phoneNumber: info?.phoneNumber,
          countryId: info?.countryId,
          city: info?.city,
          organization: info?.organization,
          position: info?.position,
          interestTopic: info?.interestTopic,
        }
      : {
          fullName: "",
          email: "",
          phoneNumber: "",
          country:'',
          countryId: "",
          city: "",
          organization: "",
          position: "",
          interestTopic: "",
        }
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (
      (["phoneNumber"].includes(name) && !/^\d*$/.test(value)) ||
      (["fullName"].includes(name) &&
        !/(^$)|(^[a-zA-Z]+(\s?|(\s+[a-zA-Z]+))?$)/.test(value))
    ) {
      return;
    }
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setFormDataErr((prevData) => ({ ...prevData, [name]: "" }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const dataFormErr = {};
    for (const [key, value] of Object.entries(formData)) {
      if(!['countryId'].includes(key)){
        if (value === "" || (typeof value === "string" && value.length < 3)) {
          dataFormErr[key] = "err"; // Marca el error si el campo está vacío o es menor a 3 caracteres
        }
      }
    }
    setFormDataErr(dataFormErr);
    if (Object.values(dataFormErr).length > 0) {
      Swal.fire({
        icon: "warning",
        title: "",
        text: "Faltan campos por tramitar",
      });
    } else {
      setLoading(true);
      if (isEdit) {
        consumerRegisterEdit(info.customerId, formData, setProgressBar)
          .then((results) => close())
          .catch((err) =>
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: err.response?.data?.message,
            })
          )
          .finally(() => setLoading(false));
      } else {
        consumeRegister(formData, setProgressBar)
          .then((results) => close())
          .catch((err) =>
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: err.response?.data?.message,
            })
          )
          .finally(() => setLoading(false));
      }
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      {/* {isEdit && info.customer_picture?.idFile && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Avatar
            alt="Remy Sharp"
            sx={{ width: 100, height: 100 }}
            src={`${process.env.REACT_APP_URL_API}/${info.customer_picture.destination}/${info.customer_picture.filename}?token=${auth.token}`}
          />
        </div>
      )} */}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            label="Nombre"
            variant="outlined"
            autoComplete="off"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            fullWidth
            margin="normal"
            error={formDataErr.fullName === "err"}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <TextField
            label="Correo"
            variant="outlined"
            autoComplete="off"
            name="email"
            value={formData.email}
            onChange={handleChange}
            fullWidth
            margin="normal"
            type="email"
            error={formDataErr.email === "err"}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <TextField
            label="Teléfono"
            variant="outlined"
            autoComplete="off"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            fullWidth
            margin="normal"
            error={formDataErr.phoneNumber === "err"}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <FormControl
            fullWidth
            margin="normal"
            error={formDataErr.countryId === "err"}
          >
            <InputLabel id="country-select-label">País</InputLabel>
            <Select
              labelId="country-select-label"
              id="country-select"
              label="País"
              name="countryId"
              value={formData.countryId}
              onChange={async (e) => {
                const countryId = e.target.value;

                setSelectedCountry(countryId);
                const selectedCountryData = countries.find(
                  (c) => c.iso2 === countryId
                );
                if (selectedCountryData) {
                  try {
                    const response = await customerGetCities(
                      selectedCountryData.country
                    );
                    setFilteredCities(response.data);
                  } catch (error) {
                    console.error("Error al obtener ciudades:", error);
                    setFilteredCities(["Otro"]);
                  }
                  setFormData((prevData) => ({
                    ...prevData,
                    countryId,
                    country: selectedCountryData.country,
                    // countryId: selectedCountryData.country,
                    city: "", // Resetea la ciudad cuando se cambia el país
                  }));
                } else {
                  setFilteredCities([]);
                }
                setFormDataErr((prevData) => ({
                  ...prevData,
                  countryId: '',
                }));
              }}
            >
              {countries.map(({ iso2, country }) => (
                <MenuItem key={iso2} value={iso2}>
                  {country}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <FormControl
            fullWidth
            margin="normal"
            error={formDataErr.city === "err"}
          >
            <InputLabel id="city-select-label">Ciudad</InputLabel>
            <Select
              labelId="city-select-label"
              id="city-select"
              label="Ciudad"
              name="city"
              value={formData.city}
              onChange={(e) => {
                const city = e.target.value;
                setFormData((prevData) => ({ ...prevData, city }));
                setFormDataErr((prevData) => ({ ...prevData, city: "" }));
              }}
              disabled={!selectedCountry}
            >
              {filteredCities.map((city) => (
                <MenuItem key={city} value={city}>
                  {city}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <TextField
            label="Organización"
            variant="outlined"
            autoComplete="off"
            name="organization"
            value={formData.organization}
            onChange={handleChange}
            fullWidth
            margin="normal"
            error={formDataErr.organization === "err"}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <TextField
            label="Cargo"
            variant="outlined"
            autoComplete="off"
            name="position"
            value={formData.position}
            onChange={handleChange}
            fullWidth
            margin="normal"
            error={formDataErr.position === "err"}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <TextField
            label="Tema de interés"
            variant="outlined"
            autoComplete="off"
            name="interestTopic"
            value={formData.interestTopic}
            onChange={handleChange}
            fullWidth
            margin="normal"
            multiline
            rows={4} // Puedes ajustar el número de filas si es necesario
            error={formDataErr.interestTopic === "err"}
          />
        </Grid>
      </Grid>
      <LoadingButton
        type="submit"
        variant="contained"
        color="primary"
        disabled={loading}
        fullWidth
        loading={loading}
        loadingPosition="end"
        endIcon={<HowToRegIcon />}
      >
        {isEdit ? "Editar" : "Registrar"}
      </LoadingButton>
      {loading && (
        <>
          <LinearProgress
            variant="determinate"
            value={progressBar}
            sx={{ mt: 2 }}
          />
          <div style={{ textAlign: "center" }}>
            <CircularProgress size={11} />
            <span> Progreso {progressBar}%</span>
          </div>
        </>
      )}
    </form>
  );
}
